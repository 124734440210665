<template>
    <div class="quiz-container">
        <div class="quiz-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>小测列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="quiz-list">
                <div class="course-list" v-if="!recordId">
                    <span class="select-text">课程筛选：</span>
                    <el-select v-model="courseValve" placeholder="请选择" clearable @change="selectCourse">
                        <el-option v-for="courseItem in courseList" :key="courseItem.course_id" :label="courseItem.course_name" :value="courseItem.course_id"></el-option>
                    </el-select>
                </div>
                <el-table :data="quizList" border style="width: 100%; flex: 1; margin-top: 20px;" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                    <el-table-column prop="paper_name" label="试卷名称">
                      <template slot-scope="scope">
                        <span class="text-overflow">{{scope.row.paper_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="t_name" label="创建人"></el-table-column>
                    <el-table-column label="提交状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_submit === 1">已提交</span>
                            <span v-else-if="scope.row.is_submit === 0">未提交</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="批阅状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_read === 0">已批阅</span>
                            <span v-else-if="scope.row.is_read === 1">未批阅</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="issue_time" label="发布时间"></el-table-column>
                  <el-table-column prop="end_time" label="结束时间"></el-table-column>
                  <el-table-column prop="status" label="是否结束">
                    <template slot-scope="scope">
                      <span v-if="scope.row.status === 1">已结束</span>
                      <span v-else-if="scope.row.status === 0">未结束</span>
                    </template>
                  </el-table-column>
                    <el-table-column label="详情">
                        <template slot-scope="scope">
                            <el-button type="primary" :disabled="scope.row.status === 0" @click="toQuizDetail(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center" background style="margin: 20px 0;"
                               :current-page.sync="quizPages.currentPageNum"
                               :page-size="quizPages.eachPageNum"
                               :total="quizPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="quizCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                classId: Number(this.$route.query.classId) || '',
                className: this.$route.query.className || '',
                recordId: Number(this.$route.query.recordId) || '',
                courseValve: '',
                courseList: [],
                quizList: [],
                quizPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                studentName: localStorage.getItem('sUsername'),
                studentId: Number(localStorage.getItem('s_id')),
            }
        },
        mounted() {
            this.getCourseList();
            // this.getQuizList();
        },
        methods: {
            getCourseList() {
                let params = {
                    parent_id: 'no',
                    type: 1,
                    sort: 1
                }
                this.$shttp.axiosGetBy(this.$api.admin_courseLists, params, (res) => {
                    if (res.code === 200) {
                        this.courseList = res.list;
                        this.courseValve = res.list[0].course_id;
                        if (this.courseValve) {
                            this.getQuizList();
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            selectCourse(val) {
                this.getQuizList();
                this.courseValve = val;
            },
            getQuizList() {
                let params = {
                    c_id: this.classId,
                    page: this.quizPages.currentPageNum,
                    limit: this.quizPages.eachPageNum,
                    course_id: this.courseValve
                }
                if (this.recordId) {
                    params.course_record_id = this.recordId;
                }
                this.$shttp.axiosGetBy(this.$api.seeStudentQuizList, params, (res) => {
                    if (res.code === 200) {
                        this.quizList = res.data.data;
                        this.quizPages.total = res.data.total
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            quizCurrentChange(val) {
                this.quizPages.currentPageNum = val;
                this.getQuizList();
            },
            toQuizDetail(row) {
                if (row.is_submit === 0) {
                    let routeUrl = this.$router.resolve({
                        path: '/course/PaperDetail',
                        query: {
                            id: row.quiz_id,
                        }
                    })
                    window.open(routeUrl .href, '_blank')
                } else if (row.is_submit === 1) {
                    if (row.is_read === 1) {
                        this.$router.push({
                            path: '/teacherClass/approvedDetail',
                            query: {
                                quizId: row.quiz_id,
                                studentId: this.studentId,
                                classId: this.classId,
                                className: this.className,
                                paperName: row.paper_name,
                                studentName: this.studentName,
                            }
                        })
                    } else if (row.is_read === 0) {
                        this.$router.push({
                            path: '/teacherClass/allApprovedDetail',
                            query: {
                                quizId: row.quiz_id,
                                studentId: this.studentId,
                                classId: this.classId,
                                className: this.className,
                                paperName: row.paper_name,
                                studentName: this.studentName,
                            }
                        })
                    }
                }
            },
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .quiz-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .quiz-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .quiz-list {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                .course-list {
                    padding: 20px 20px 0;
                }
            }
        }
    }
</style>